import Vue from 'vue';
import Vuex from 'vuex'
import 'vue-toast-notification/dist/theme-sugar.css';

import VueToast from 'vue-toast-notification';

Vue.use(VueToast, {
  position: 'bottom-right',
  duration: 3000,
});
Vue.use(Vuex)
export default new Vuex.Store({
  namespaced: true,
  actions: {
    success(ctx, message) {
      Vue.$toast.success(message);
    },
    error(ctx, message) {
      Vue.$toast.error(message);
    },
    warning(ctx, message) {
      Vue.$toast.warning(message);
    },
    default(ctx, message) {
      Vue.$toast.default(message);
    },
    info(ctx, message) {
      Vue.$toast.info(message);
    },
  },
});
