import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/plugins/i18n/locales/ar.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'ar', // set locale
  fallbackLocale: 'ar',
  messages: {
    ar: messages,
  }, // set locale messages
});

if (!localStorage.getItem('language')) {
  localStorage.setItem('language', i18n.locale);
}
export const locales = [
  {
    title: 'العربية',
    img: require('@/assets/images/flags/ar.png'),
    locale: 'ar',
  },

  // {
  //   title: 'English',
  //   img: require('@/assets/images/flags/en.png'),
  //   locale: 'en',
  // },
];

const loadedLanguages = ['ar'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  localStorage.setItem('language', lang)

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.json').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
  /* eslint-enable */
}
