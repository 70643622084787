import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vue-toast-notification/dist/theme-sugar.css';
import Vue from 'vue'
import axios from '@axios';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'
import ASPlugin from '@/plugins/AS'
import environment from '../environment'

Vue.config.productionTip = false
axios.defaults.baseURL = environment[process.env.NODE_ENV].APP_API_URL;

Vue.use(ASPlugin);
new Vue({
  router,
  store,
  iconfont: 'mdi',
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
