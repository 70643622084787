import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import toast from './toast'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    entering_level_0: 0,
    entering_level_1: 0,
    loggedIn: localStorage.getItem('userData') !== null,
    user: null,
    pageTitle: null,
  },
  getters: {
    isAuthenticated(state) {
      return state.loggedIn;
    },
    user(state) {
      return state.user
    },
  },
  mutations: {
    SET_ENTERING_LEVEL(ctx, {
      level0 = 0,
      level1 = 0,
    }) {
      this.state.entering_level_0 = level0;
      this.state.entering_level_1 = level1;
    },
  },
  actions: {
    setUser(ctx, user) {
      ctx.state.user = user;
      ctx.state.loggedIn = true;
      localStorage.setItem('userData', JSON.stringify(user));
      router.push('/')
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    toast,
  },
})
