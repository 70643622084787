import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import auth from '@/store/auth'

// import { Role } from '../_helpers/roles'

// eslint-disable-next-line import/no-cycle
import useRole from '@/plugins/useRole';

import { i18n } from '@/plugins/i18n'
import store from '@/store'

const { canAccess } = useRole()
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/authentication/login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/profile/index.vue'),
    meta: {
      layout: 'content',
      permission: 'update_users',
    },
  },

  /// Begin User Routes
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/pages/users/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_users',
    },
  },

  /// Begin Roles Routes
  {
    path: '/roles',
    name: 'roles.index',
    component: () => import('@/views/pages/roles-permissions/roles/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_roles',
    },
  },

  /// Begin Permission Routes
  {
    path: '/permissions',
    name: 'permissions.index',
    component: () => import('@/views/pages/roles-permissions/permissions/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_permissions',
    },
  },

  /// Begin CheckPoint Routes
  {
    path: '/checkpoints',
    name: 'checkpoints.index',
    component: () => import('@/views/pages/checkpoints/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_checkpoint',

    },
  },

  /// Begin Identifiers Routes
  {
    path: '/Identifiers',
    name: 'Identifiers.index',
    component: () => import('@/views/pages/Identifiers/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_identifiers',

    },
  },

  {
    path: '/peoples',
    name: 'peoples.index',
    component: () => import('@/views/pages/peoples/peoples.vue'),
    meta: {
      layout: 'content',
      permission: 'read_peoples',
    },
  },

  {
    path: '/peoples_deleted',
    name: 'peoples.deleted',
    component: () => import('@/views/pages/peoples/deleted.vue'),
    meta: {
      type: 'deleted',
      layout: 'content',
      permission: 'create_peoples',
    },
  },

  {
    path: '/citizens',
    name: 'citizens.index',
    component: () => import('@/views/pages/peoples/citizen.vue'),
    meta: {
      layout: 'content',
      permission: 'read_peoples',

    },
  },

  {
    path: '/settings',
    name: 'site.settings',
    component: () => import('@/views/pages/settings/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_settings',

    },
  },

  {
    path: '/peoples/create',
    name: 'peoples.create',
    component: () => import('@/views/pages/peoples/create.vue'),
    meta: {
      navActiveLink: 'peoples.index',
      type: 'people',
      layout: 'content',
      permission: 'create_peoples',
    },
  },

  {
    path: '/citizens/create',
    name: 'citizens.create',
    component: () => import('@/views/pages/peoples/create.vue'),
    meta: {
      navActiveLink: 'citizens.index',
      type: 'citizen',
      layout: 'content',
      permission: 'create_peoples',

    },
  },

  {
    path: '/people/:id',
    name: 'peoples.people',
    component: () => import('@/views/pages/peoples/update.vue'),
    meta: {
      layout: 'content',
      permission: 'update_peoples',

    },
  },

  /// Begin show images Routes
  {
    path: '/statistics/images/:id',
    name: 'statistics.images',
    component: () => import('@/views/pages/statistics/images.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },

  /// Begin Site  Cars Routes
  {
    path: '/cars/index',
    name: 'cars.index',
    component: () => import('@/views/pages/cars/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_cars',

    },
  },
  {
    path: '/cars/deleted',
    name: 'cars.deleted',
    component: () => import('@/views/pages/cars/deleted.vue'),
    meta: {
      type: 'car_deleted',
      layout: 'content',
      permission: 'read_cars',

    },
  },

  {
    path: '/cars/index/:id',
    name: 'cars.show',
    component: () => import('@/views/pages/cars/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_cars',

    },
  },

  /// Begin Site  General Routes
  {
    path: '/statistics/authorized',
    name: 'statistics.authorized',
    component: () => import('@/views/pages/statistics/authorized.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },

  /// Begin Site  enterByCall Routes
  {
    path: '/statistics/enterByCall',
    name: 'statistics.enterByCall',
    component: () => import('@/views/pages/statistics/identifiers.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },

  /// Begin Site  funerals Routes
  {
    path: '/statistics/funerals',
    name: 'statistics.funerals',
    component: () => import('@/views/pages/statistics/funerals.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },

  /// Begin Site  general Routes
  {
    path: '/statistics/general/people',
    name: 'statistics.people',
    component: () => import('@/views/pages/statistics/general/people.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },
  {
    path: '/statistics/general/citizen',
    name: 'statistics.citizen',
    component: () => import('@/views/pages/statistics/general/citizen.vue'),
    meta: {
      layout: 'content',
      permission: 'read_statistics',

    },
  },
  {
    path: '/devices',
    name: 'devices.index',
    component: () => import('@/views/pages/devices/index.vue'),
    meta: {
      layout: 'content',
      permission: 'read_devices',

    },
  },

  {
    path: '*',
    name: 'error-404',
    component: () => import('@/views/pages/errors/404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/403',
    name: 'error-403',
    component: () => import('@/views/pages/errors/403.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    store.state.pageTitle = null;
  }
  if (to.path === '/') {
    return next({ name: 'home' });
  }

  const { isAuthenticated } = auth.getters;
  const canAccessRoute = canAccess(to.name)
  if (!auth.state.loaded && isAuthenticated) {
    await auth.dispatch('getUser');
  }

  if (!canAccessRoute) {
    return next({ name: '403' })
  }
  if ((isAuthenticated && to.name === 'login')) {
    return next({ name: 'home' })
  }

  if (!isAuthenticated && to.name !== 'login') {
    return next({ name: 'login' })
  }

  return next()
})

const currentRouteName = to => {
  if (to.meta.name) {
    switch (typeof to.meta.name) {
      case 'string':
        return to.meta.name;
      case 'function':
        return to.meta.name(to)
      default:
        return '';
    }
  }

  return to.name;
};
router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = `نظام السيطرات - ${i18n.t(`router.${currentRouteName(to)}`).toString()}`
  });
});
export default router
