var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItems},scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var isVerticalNavMenuActive = ref.isVerticalNavMenuActive;
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
return [_c('div',{staticClass:"navbar-content-container",class:{'expanded-search': _vm.shallShowFullSearch}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-icon',{staticClass:"me-3",on:{"click":toggleVerticalNavMenuActive}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('h3',[_vm._v(" "+_vm._s(_vm.currentRouteName)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center right-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.store && _vm.store.state)?_c('v-chip',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"color":_vm.entering_0_color,"to":{ name: 'site.settings' }}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('entering_levels.' + _vm.store.state.entering_level_0))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("السماح بالدخول ايام الزيارات (السيطرات الداخلية)")])]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.store && _vm.store.state)?_c('v-chip',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"color":_vm.entering_1_color,"to":{ name: 'site.settings' }}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('entering_levels.' + _vm.store.state.entering_level_1))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("السماح بالدخول ايام الزيارات (السيطرات الخارجية)")])]),_c('app-bar-i18n'),_c('app-bar-theme-switcher',{staticClass:"mx-4"}),_c('app-bar-user-menu')],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://kf.iq/"}},[_vm._v(" مركز الكفيل ")])]),_c('div',{staticClass:"align-center d-none d-md-flex"})])]},proxy:true}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }