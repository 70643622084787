const environment = {
  production: {
    // APP_API_URL: 'https://ameerfakhri.local.kf.iq/checkpoints',
    // APP_PATH: '/check_points_front/',

    APP_API_URL: 'https://cars.local.kf.iq/api',
    APP_PATH: '/',
  },
  development: {
    // APP_API_URL: 'http://cars.local.kf.iq/api',
    APP_API_URL: 'http://127.0.0.1:8000/',
    APP_PATH: '/',
  },
};

module.exports = environment;
