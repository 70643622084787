import { mdiFileOutline, mdiHomeOutline } from '@mdi/js'

export default [

  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    to: 'home',
    children: [
      {
        title: 'CRM',
        to: 'dashboard-crm',
      },
      {
        title: 'Analytics',
        to: 'dashboard-analytics',
      },
      {
        title: 'eCommerce',
        to: 'dashboard-eCommerce',
      },
    ],
  },

]
